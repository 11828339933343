
.dropdown-toggle.btn.btn-primary {
    width: 80px;
    padding: 6px 0;
    border-radius: 30px;
}

.dropdown-menu {
    width: 80px;
    border-radius: 12px;
    min-width: 0;
    padding: 5px 0;
}

.dropdown-item {
    padding: 5px 16px;
    text-align: center;
}

.dropdown.출석 > button {
    background-color: var(--bs-primary);
    color: white;
}

.dropdown.지각 > button {
    background-color: var(--blue3);
    color: var(--bs-primary);
}

.dropdown.결석 > button {
    background-color: var(--sub-title3);
    color: var(--grey2);
}

.dropdown.Flutter > button {
    background-color: var(--blue2);
    color: var(--hover);
}

.dropdown.React > button {
    background-color: var(--blue1);
    color: white;
}

.dropdown.Spring > button {
    background-color: var(--green);
    color: var(--hover);
}

.dropdown.AI > button {
    background-color: var(--orange);
    color: var(--hover);
}

.dropdown.Design > button {
    background-color: var(--purple);
    color: var(--hover);
}