.react-calendar {
    background-color: transparent;
    border: none;
    border-radius: 8px;
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: clamp(12px, 1.2vw, 16px);
}

.react-calendar__navigation button {
    background: none;
    border: none;
    color: var(--airblue);
    cursor: pointer;
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: clamp(18px, 2.5vw, 32px);
}

.react-calendar__month-view__weekdays__weekday > * {
    text-decoration: none !important;
    text-transform: uppercase;
    color: var(--blue1);
    font-weight: 400;
    font-size: clamp(10px, 1.5vw, 18px);
    font-family: 'Pretendard', sans-serif;
}

.react-calendar__month-view__days__day {
    display: flex;
    height: calc(88 * var(--bs-aspect-ratio-height));
    width: calc(88 * var(--bs-aspect-ratio-width));
    background: white;
    border-radius: 8px;
    color: var(--blue1);
    cursor: pointer;
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: clamp(10px, 1.5vw, 18px);
}

.modal {
    width: 720px;
    height: 600px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 5;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-content: center;
    justify-content: center;
    justify-items: center;
}


.modal-content {
    border: transparent;
    margin: 0 78px 58px 78px;
}

.modal-content input {
    margin-left: 24px;
    padding: 8px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--border);
    align-content: center;
}

.modal-content button {
    cursor: pointer;
    border: none;
    color: var(--airblue);
}
.modal-content input {
    font-size: clamp(12px, 1.2vw, 16px);
}

.modal-button {
    font-size: clamp(16px, 1.8vw, 20px);
}

.modal-plus-button {
    font-size: clamp(20px, 2vw, 25px);
}

.event {
    font-size: clamp(12px, 1.2vw, 14px);
}
.modal-button {
    cursor: pointer;
    border: 1px solid var(--airblue) !important;
    width: 64px;
    height: 36px;
    font-family: 'Pretendard', sans-serif;
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 20px;
}

.modal-plus-button {
    cursor: pointer;
    border: none;
    background-color: var(--airblue);
    color: white!important;
    width: 27px;
    height: 27px;
    font-family: 'Pretendard', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 25px;
}

.modal-content button:hover {
    background-color: var(--blue1);

}

.event {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 55px;
    height: 40px;
    font-size: clamp(8px, 1.2vw, 14px);
    z-index: 1;
    color: var(--sub-title5);
}

.react-calendar__tile {
    position: relative;
}

.MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
    margin: 16px 0 28px 0;
    width: 140px;
}

.MuiButtonBase-root {
    font-family: 'Pretendard', sans-serif !important;
    border-radius: 4px!important;
}
.MuiButtonBase-root:hover{
    background-color: transparent !important;
    color: var(--blue1) !important;
}
.MuiFormLabel-root {
    color: var(--blue1) !important;
    font-family: 'Pretendard', sans-serif !important;
    display: flex!important;
    font-size: clamp(12px, 1.2vw, 16px) !important;;
}

.MuiInputBase-root{
    width: 158px;
    height: 40px;
    font-size: clamp(12px, 1.2vw, 16px) !important;
}

.MuiOutlinedInput-input {
    font-family: 'Pretendard', sans-serif !important;
    border:none!important;
}
.MuiButtonBase-root{
    margin: 0 !important;
}
.MuiIconButton-root{
    background-color: transparent;
}
.MuiInputBase-input{
    margin: 5px 8px!important;
    text-align: center;
}
.MuiInputAdornment-root{
    margin: 0 !important;
}
