.TopNav {
    width: 100%;
    height: calc(240 * var(--bs-aspect-ratio-height));
    padding: 0;
    background: linear-gradient(180deg, #E4F5FF 0%, rgba(255, 255, 255, 0.95) 52%, rgba(255, 255, 255, 0) 100%);
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
    pointer-events: none;
}

.TopNav-Content {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding-top: calc(50 * var(--bs-aspect-ratio-height));
    padding-left: calc(65 * var(--bs-aspect-ratio-width));
    padding-right: calc(65 * var(--bs-aspect-ratio-width));
    pointer-events: auto;
}

.Logo {
    width: clamp( 30px, 8vw, 120px);
    height: auto;
}

#basic-navbar-nav {
    display: flex;
    justify-content: space-between;
    color: var(--sub-title5);
}

.Menu, .Actions {
    display: flex;
    align-items: center;
}

.Text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    font-size: clamp(7px, 2vw, 20px);
}


.navbar-expand .navbar-nav .nav-link {
    margin: 0 calc(12 * var(--bs-aspect-ratio-width));
    padding: 4px 8px;
    box-sizing: content-box;
}

.nav-item .dropdown {
    margin: 0 calc(28 * var(--bs-aspect-ratio-width));
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
}

.Icon {
    font-size: 32px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Nav-Icon {
    width: clamp(30px, 3vw, 40px);
    height: auto;
}