.page-div {
    width: 100%;
    height: 100vh;
    padding-top: calc(100 * var(--bs-aspect-ratio-height));
    padding-bottom: calc(100 * var(--bs-aspect-ratio-height));
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    overflow-y:auto;
}

.code-input {
    width: 100%;
    height: clamp(40px,5vw, 52px);
    padding: 0 16px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    border: 1px solid var(--line);
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 52px;
    font-size: clamp(14px, 1.8vw, 16px);
    box-sizing: border-box;
}
.code-input::placeholder {
    font-size: clamp(13px, 1.8vw, 18px);
    color: var(--sub-title5);
    font-family: 'Pretendard', sans-serif;
}

.code-submit {
    width: 100%;
    height: clamp(40px,5vw, 64px);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button);
    border:none;
    color: white;
    border-radius: 8px;
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: clamp(14px, 1.8vw, 21px);
}


.attendance-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.calendar-section {
    flex: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .attendance-container {
        flex-direction: column-reverse;
        width: 100%;
        padding: 20px;
        margin-top: calc(240 * var(--bs-aspect-ratio-height));
        overflow-y: visible;
    }

    .calendar-section {
        flex: none;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .page-div {
        padding-top: 0;
        align-items: flex-start;
        height: auto;
    }

    .calendar {
        width: 100% !important;
        max-width: 400px;
    }
}

.check-icon {
    height: clamp(50px, 8vw, 100px);
    color: var(--airblue);

}

.check-icon.disabled {
    color: var(--sub-title1);
}

.attendance-title {
    margin-top: 10px;
    font-size: clamp(1.5rem, 3vw, 60px);
    color: var(--airblue);
    font-weight: 800;
    font-family: 'Pretendard', sans-serif !important;
}

.attendance-title.disabled {
    font-size: clamp(1.5rem, 3vw, 60px );
    color: var(--sub-title1);
    font-weight: 800;
    font-family: 'Pretendard', sans-serif !important;
}

.attendance-description {
    font-family: 'Pretendard', sans-serif !important;
    font-size: clamp(14px, 1.8vw, 16px);
    font-weight: 500;
    color: var(--sub-title5);
    text-align: center;
    white-space: pre-line;
}

.attendance-form-container {
    width: 100%;
    margin-top: clamp(20px, 4vw, 60px);
    padding: 0 clamp(20px, 6vw, 80px);
    display: flex;
    flex-direction: column;
}
.attendance-box {
    width: 80%;
    aspect-ratio: 1/1;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 0px 28px 0px rgba(0, 21, 94, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: clamp(15px, 3vw, 30px);
}

.check-icon.success {
    color: #4CAF50!important;
    background-color: transparent!important;
}

.check-icon.late {
    color: #ffc045 !important;
    background-color: transparent!important;
}

.attendance-title.success {
    color: #4CAF50!important;
    background-color: transparent!important;
}

.attendance-title.late {
    color: #ffc045!important;
    background-color: transparent!important;
}

.error-message {
    color: #FF5252;
    font-size: clamp(12px, 1.5vw, 14px);
    margin-top: 8px;
    text-align: center;
    font-family: 'Pretendard', sans-serif;
}

.code-submit.disabled {
    background-color: var(--sub-title1);
    cursor: not-allowed;
}

.check-icon,
.attendance-title,
.code-input,
.code-submit {
    transition: all 0.3s ease;
}