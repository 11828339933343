* {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
  overscroll-behavior: none;
  background-size: 100% 100%;
}