.parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.child {
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-title{
    display: flex;
    font-size: 60px;
    font-weight: 800;
    align-content: center;
    justify-content: center;
    color: var(--airblue);
}

.sub-text{
    display: flex;
    font-size: 16px;
    font-weight: 500;
    align-content: center;
    justify-content: center;
    margin:20px;
    color: var(--sub-title5);
}

.input-text{
    display: flex;
    margin: 20px;
    border-radius: 8px;
    width: 472px;
    height: 52px;
    align-content: center;
    justify-content: center;
    border-color:var(--sub-title5);
    border-width: 1px;
    padding: 21px;
    color: var(--sub-title5);
}
.login-button {
    display: flex;
    border-radius: 8px;
    width: 472px;
    height: 64px;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    border-width: 1px;
    font-size: 21px;
    font-weight: 700;
    font-family: 'Pretendard', sans-serif;
    background-color: var(--airblue);
    color: white;
}
@media (max-width: 768px) {
    .login-title {
        font-size: 30px;
    }

    .sub-text {
        font-size: 12px;
    }

    .input-text {
        display: flex;
        width: 250px;
        height: 40px;
        padding: 10px;
    }

    .login-button {
        width: 250px;
        height: 50px;
        font-size: 16px;
    }
}