.App {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.screen::-webkit-scrollbar {
  width: 12px;
}

.screen::-webkit-scrollbar-thumb {
  background: var(--sub-title6); /* 스크롤바 색상 */
  border-radius: 10px; /* 스크롤바 둥근 테두리 */
}

.screen::-webkit-scrollbar-track {
  background: var(--grey2);  /*스크롤바 뒷 배경 색상*/
  padding: 1px;
  border-left: 1px solid #d3d3d3;
}

.bg-image-gradient {
  position: relative;
  background: linear-gradient(180deg, rgba(228, 245, 255, 0.00) 0%, #E4F5FF 100%);
  mix-blend-mode: multiply;
  z-index: 0;
}

.bg-image-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/images/svg/bg_image.svg");
  background-size: 120%;
  background-position: center;
  opacity: 0.1;
  z-index: -1;
}

.bg-gradient {
  position: relative;
  z-index: 0;
}

.bg-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(228, 245, 255, 0.00) 0%, #E4F5FF 100%);
  mix-blend-mode: multiply;
  z-index: -1;
}

.bg-image {
  position: relative;
  z-index: 0;
}

.bg-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/images/svg/bg_image.svg");
  background-size: 120%;
  background-position: center;
  opacity: 0.1;
  z-index: -1;
}
