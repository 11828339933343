/*Font Family*/

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap');

@font-face {
    font-family: 'Pretendard-Black';
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-ExtraBold';
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-Bold';
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-SemiBold';
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-Medium';
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-Light';
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-ExtraLight';
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard-Thin';
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: "Bruno Ace", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Audiowide", sans-serif;
    font-weight: 400;
    font-style: normal;
}

/*앱에서 사용하는 폰트 정의*/
.h1-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 800 !important;
    font-size: 60px !important;
}

.h2-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 800 !important;
    font-size: 52px !important;
}

.h3-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 800 !important;
    font-size: 40px !important;
}

.h4-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 800 !important;
    font-size: 32px !important;
}

.project-title-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 600 !important;
    font-size: 32px !important;
}

.btn-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 700 !important;
    font-size: clamp(16px, 1.4vw, 21px);
}
.modal-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 700 !important;
    font-size: 24px !important;
}

.top-bar-btn-txt-en {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 500 !important;
    font-size: 21px !important;
}

.top-bar-btn-txt-ko {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}

.body1-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}

.body2-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.body3-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 300 !important;
    font-size: 18px !important;
}

.caption-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 400 !important;
    font-size: clamp(10px, 1.2vw, 14px) !important;
}

.profile-modal-title-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 700 !important;
    font-size: 20px !important;
}

.profile-modal-subtitle-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.profile-modal-caption-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 300 !important;
    font-size: 10px !important;
}

.profile-modal-category-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.profile-modal-normal-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 400 !important;
    font-size: clamp(12px, 1.6vw, 14px)!important;
}

.profile-card-title-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.profile-card-subtitle-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}

.profile-card-caption-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 300 !important;
    font-size: 10px !important;
}

.sub-calendar-txt {
    font-family: 'Pretendard', sans-serif !important;
    font-weight: 500 !important;
    font-size: clamp(14px, 1.4vw, 18px)!important;
}