.member-page {
    overflow-y: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    padding: 16px;
    max-width: 100vw;
    overflow-x: hidden;
}
.member-profile-image {
    width: 168px;
    height: 168px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 0 auto 10px;
}

.scrollable-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: calc(250 * var(--bs-aspect-ratio-height));
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    gap: 24px;
}

.scrollable-container::-webkit-scrollbar {
    display: none;
}

.member-card {
    width: 212px;
    height: 317px;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 10px 12px 0 rgba(0, 22, 56, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .member-card {
        width: 100px;
        height: 210px;
        padding: 24px 12px;
        flex-shrink: 0;
        margin: 0 10px;
    }
    .member-profile-image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
        display: block;
        margin: 0 auto 10px;
    }
    .members-row {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: center;
        padding: 0 8px;
        box-sizing: border-box;
    }

    ._UnitContainer {
        width: calc(50% - 6px);
        margin: 0;
    }

    ._UnitContainer .members-grid {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }

    .member-card {
        scroll-snap-align: start;
    }

    .scrollable-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: calc(50 * var(--bs-aspect-ratio-height));
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;
        gap: 24px;
    }
}
