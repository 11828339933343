.side-bar-menu-title {
    margin-top: 16px;
    margin-bottom: 0;
    line-height: 48px;
    color: var(--sub-title5);
}

.side-bar-menu {
    margin-bottom: 0;
    padding-left: 1.4rem;
    list-style-position:outside;
}

.side-bar-menu-txt {
    text-decoration: none;
    line-height: 48px;
    color: var(--sub-title5);
    padding-left: 5px;
}

.selected {
    color: var(--card-hover);
}
