.input-group {
    margin-bottom: 1rem;
    height: 48px;
}

.input-group-text {
    width: 72px;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    background-color: var(--blue3);
    color: var(--bs-primary)
}

.form-control {
    padding: 0 18px;
}

.form-select {
    background-position: left 18px center;
    padding-left: 44px;
}