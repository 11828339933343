.screen {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
}

.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
}


.svg-inline--fa.fa-circle-user {
    height: 164px;
    color: var(--bs-primary);
    margin-bottom: 30px;
}
.card-container {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.fa-circle-user {
    font-size: 100px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .screen{
        margin: 10px 0 40px 0;
        overflow-y:auto;
    }
    .content-wrapper {
        flex-direction: column;
        gap: 1.5rem;
    }

    .card-container {
        height: 400px !important;
        width: 80%!important;
        padding: 10px;
    }

    .svg-inline--fa.fa-circle-user {
        height: 80px;
        margin-bottom: 10px;
        color: var(--card-hover);
    }
    .h4-txt {
        font-size: 16px;
        margin-top: 20px;
        text-align: center;
    }
}
