.main-calligraphy-div {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../../../public/images/svg/bg_image.svg");
    background-size: 120%;
    background-position: center;
    flex-shrink: 0;
}

.title {
    font-family: Audiowide, serif;
    font-size: clamp(80px, 20vw, 312px);
}

.title-meaning {
    margin: 3vw;
    font-family: "Bruno Ace", serif;
    font-size: clamp(10px, 4vw, 56px);
    background: linear-gradient(90deg, #0B1127 0%, #004DC2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.circle {
    width: calc(156 * var(--bs-aspect-ratio-width));
    height: calc(156 * var(--bs-aspect-ratio-width));
    border-radius: 50%;
    box-sizing: border-box;
    background-color: var(--blue4);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.circle-in-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--dark-grey);
}

.main-intro-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: static;
}
