html {
    --top-bar: #F3FBFF;
    --top-bar-title: #3A3A3A;
    --sub-title1: #818181;
    --sub-title2: #575757;
    --sub-title3: #797979;
    --sub-title4: #ABABAB;
    --sub-title5: #656565;
    --sub-title6: #B6B6B6;

    --line: #AFAFAF;
    --not-selected: #D9D9D9;
    --wrong: #FF0000;
    --button: #004DC2;
    --checked: #CACACA;

    --project: #9C9ED3;

    --signup-bt: #004DC2;

    --gachon: #0D4D91;
    --file-back: #F5F5F5;

    --flutter-unit: #0D8496;
    --spring-unit: #888C2B;
    --ai-unit: #E06A1B;
    --design-unit: #776AA6;

    --card-hover: #005AE2;
    --bubble: #E4F5FF;
    --dark-grey: #656565;
    --navy: #0B1127;
    --hover: #171E39;
    --blue1: #4796FF;
    --blue2: #71BBFF;
    --blue3: #ADE1FF;
    --blue4: #E4F5FF;
    --airblue: #0056D8;
    --signup-au: #A5A5A5;
    --grey2: #f5f5f5;
    --border: #C8C8C8;
    --line1: #ADE1FF;
    --green: #74E063;
    --orange: #FF9C65;
    --purple: #B9A1FF;
}