.Footer {
    width: 100%;
    height: calc(200 * var(--bs-aspect-ratio-height));
    padding: 0;
    background: linear-gradient(0deg, #E4F5FF 0%, rgba(255, 255, 255, 0) 100%);
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    pointer-events: none;
}