.inner {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-right: 20px;
}

.inner.scroll {
    padding-right: 0;
    z-index: 1;
}