@import "../../common/Color.css";

.react-calendar {
    width: 80%;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.react-calendar__navigation button {
    background: none;
    border: none;
    color: var(--airblue);
    cursor: pointer;
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 32px
}

.react-calendar__month-view__weekdays__weekday > * {
    text-decoration: none !important;
    text-transform: uppercase;
    text-align: center;
    color: var(--blue1);
    font-weight: 400;
    font-size: 18px;
    font-family: 'Pretendard', sans-serif;
}

.react-calendar__month-view__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.react-calendar__month-view__days__day {
    display: flex;
    width: calc(88 * var(--bs-aspect-ratio-width));
    height: calc(88 * var(--bs-aspect-ratio-height));
    background: white;
    border: 1px solid #656565;
    border-radius: 8px;
    color: var(--blue1);
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 18px;
}


.react-calendar__tile--active {
    background-color: #f0f0f0 !important;
}

.react-calendar__tile--now {
    background-color: white !important;
}
.calendar-cell {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-100 {
    background-color: var(--card-hover);
    color: white;
}

.bg-75 {
    background-color: var(--blue1);
    color: white;
}

.bg-50 {
    background-color: var(--blue2);
    color: white;
}

.bg-25 {
    background-color: var(--blue3);
    color: white;
}

.bg-0 {
    background-color: white;
    color: var(--blue1);
}

.none {
    background-color: white;
    color: #656565;
}

.blue-border {
    border: 1px solid var(--blue1) !important;
}

.present {
    background-color: var(--blue1)!important;
    color: white!important;
}

.late {
    background-color: var(--blue3)!important;
    color: var(--blue1)!important;
}

.absent {
    background-color: gray!important;
    color: white!important;
}

.no-active {
    background-color: white !important;
    color: var(--blue1)!important;
}